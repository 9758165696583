<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <template v-else>
      <div @click="$router.push('/news')" class="py-2 px-3">
        <font-awesome-icon icon="chevron-left" class="mr-2" /> Back
      </div>
      <BannerNewsSliderComponent
        :list="bannerList"
        :detail="newsDetail"
        :date="date"
      />
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import OtherLoading from "@/components/other/OtherLoading";
import BannerNewsSliderComponent from "@/components/news/detail/BannerNewsSliderComponent";
import moment from "moment";
export default {
  components: {
    Breadcrumb,
    OtherLoading,
    BannerNewsSliderComponent
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "รายละเอียดข่าวสาร",
          to: "/news"
        }
      ],
      newsId: this.$route.params.newsId,
      isLoading: true,
      bannerList: [],
      newsDetail: {},
      date: ""
    };
  },
  created: async function () {
    await this.getNewsDetail();
  },
  methods: {
    async getNewsDetail() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/MonthlyPrivilege/${this.newsId}`
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.newsDetail = data.detail;
            this.bannerList = data.detail.image_list;
            this.date = moment(data.detail.created_date).format(
              "d MMM yyyy hh:ss"
            );
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
