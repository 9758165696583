import { render, staticRenderFns } from "./BannerNewsSliderComponent.vue?vue&type=template&id=b6c0bf46&scoped=true"
import script from "./BannerNewsSliderComponent.vue?vue&type=script&lang=js"
export * from "./BannerNewsSliderComponent.vue?vue&type=script&lang=js"
import style0 from "./BannerNewsSliderComponent.vue?vue&type=style&index=0&id=b6c0bf46&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6c0bf46",
  null
  
)

export default component.exports